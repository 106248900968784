import {
  ControllerParams,
  CreateControllerFn,
} from 'yoshi-flow-editor-runtime';
// import { ITEM_TYPES } from '@wix/advanced-seo-utils/editor';
import settingsParams from './settingsParams';
import HttpClient from 'yoshi-serverless-client';
import { getConfig } from '../../api/config.api';
import {
  dummyViewModelFactory,
  ServicePageViewModel,
  servicePageViewModelFactory,
} from '../../service-page-view-model/servicePageViewModel';
import { getSettingsValues } from 'yoshi-flow-editor-runtime/tpa-settings';
import { biDefaults, generateWidgetDefaults } from './bi/consts';
import {
  dummySchedulingViewModel,
  SchedulingSectionStatus,
  SchedulingSectionViewModel,
  schedulingSectionViewModelFactory,
} from '../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { getServiceSchedulingData } from './controller-logic/scheduling-fetcher';
import { ServiceType, TimezoneType } from '@wix/bookings-uou-types';
import { SERVICE_PAGE_NAME } from './constants';
import { initUserMessage } from './controller-logic/init-user-message';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { handleNavigation } from './controller-logic/handle-navigation';
import { sendNotification } from '../../api/notification.api';

enum ScheduleStatus {
  UNDEFINED = 'UNDEFINED',
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
}

const createController: CreateControllerFn = async ({
  flowAPI,
  appData,
}: ControllerParams) => {
  const errorReporter = appData?.ravenReporter || (() => null);

  const setProps = flowAPI.controllerConfig.setProps;
  const t = flowAPI.translations.t;
  const { controllerConfig } = flowAPI;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const settings = getSettingsValues(publicData, settingsParams);

  return {
    async pageReady() {
      const { platformAPIs, appParams, wixCodeApi } = controllerConfig;
      const instance = appParams.instance as string;
      const wixSdkAdapter: WixOOISDKAdapter = new WixOOISDKAdapter(
        wixCodeApi,
        platformAPIs,
        appParams,
        controllerConfig.compId,
      );

      const { isPreview, isSSR, isEditor, isViewer } = flowAPI.environment;

      let scheduleViewModel: SchedulingSectionViewModel = {
        status: SchedulingSectionStatus.LOADING,
        isBookable: false,
      };
      let viewModel: ServicePageViewModel,
        navigateToCalendar = () => {};
      const httpClient = new HttpClient({ isSSR });

      if (isViewer || isPreview) {
        const servicePageName =
          wixCodeApi.site.currentPage.url?.substring(1) || SERVICE_PAGE_NAME;
        let servicePageIndex = wixCodeApi.location.path.indexOf(
          servicePageName,
        );
        if (servicePageIndex === -1 && isPreview) {
          servicePageIndex = 1;
        }
        const isServiceSlugExistsInPath =
          servicePageIndex !== -1 &&
          servicePageIndex !== wixCodeApi.location.path.length - 1;
        let serviceSlug = isServiceSlugExistsInPath
          ? wixCodeApi.location.path[servicePageIndex + 1]
          : '';
        if (serviceSlug) {
          serviceSlug = decodeURI(serviceSlug);
        }
        const [config, experiments] = await Promise.all([
          httpClient.request(getConfig, {
            headers: { authorization: instance },
          })(serviceSlug, isPreview),
          flowAPI.getExperiments(),
        ]);
        flowAPI.biLogger &&
          flowAPI.biLogger.util.updateDefaults({
            ...biDefaults,
            ...generateWidgetDefaults(appParams, platformAPIs, isEditor),
            serviceId: config.serviceDto.id,
            service_id: config.serviceDto.id,
            type: config.serviceDto.type,
          });

        navigateToCalendar = () => {
          handleNavigation({
            config,
            wixCodeApi,
            isPreview,
            wixSdkAdapter,
            onNavigationFailed: ({ failReasons }) => {
              httpClient.request(sendNotification, {
                headers: { authorization: instance },
              })(failReasons, config.serviceDto.id);
              flowAPI.biLogger &&
                flowAPI.biLogger.bookingsCantBookGroupsMessage({
                  widget_name: 'service_page',
                  referralInfo: 'service_page',
                  isPreview,
                  failReason: JSON.stringify(failReasons),
                });
              showUserMessage();
            },
          });
        };

        if (experiments.enabled('specs.bookings.SeoIntegration')) {
          await wixCodeApi.seo.renderSEOTags({
            itemType: 'BOOKINGS_SERVICE',
            itemData: {
              serviceResponse: config.SEO.serviceResponse,
              bookingsPolicyDto: config.bookingPolicyDto,
            },
            seoData: config.SEO.serviceResponse?.service?.advancedSeoData,
          });
        }
        const isOnlyCreatedSchedule = experiments.enabled(
          'specs.bookings.UseActiveScheduleOnly',
        );
        const isCourse = config.serviceDto.type === ServiceType.COURSE;
        const serviceSchedule = isOnlyCreatedSchedule
          ? config.SEO.serviceResponse?.schedules?.find(
              (schedule) => schedule.status === ScheduleStatus.CREATED,
            )
          : config.SEO.serviceResponse?.schedules?.[0];
        const firstSessionStart = serviceSchedule?.firstSessionStart;
        const lastSessionEnd = serviceSchedule?.lastSessionEnd;
        viewModel = servicePageViewModelFactory({
          config,
          t,
        });
        if (!isSSR) {
          getServiceSchedulingData(
            config,
            settings,
            httpClient,
            instance,
            isOnlyCreatedSchedule,
          )
            .then((schedule) => {
              const changeTimezoneCallback = (timezoneType: TimezoneType) => {
                scheduleViewModel = schedulingSectionViewModelFactory({
                  isBookable: viewModel.body.isBookable,
                  catalogSessionsDto: schedule?.sessions,
                  businessInfo: config?.businessInfo,
                  selectedTimezoneType: timezoneType,
                  isCourse,
                  firstSessionStart,
                  lastSessionEnd,
                  t,
                });
                setProps({
                  scheduleViewModel,
                });
              };
              scheduleViewModel = schedulingSectionViewModelFactory({
                isBookable: viewModel.body.isBookable,
                catalogSessionsDto: schedule?.sessions,
                businessInfo: config?.businessInfo,
                isCourse,
                firstSessionStart,
                lastSessionEnd,
                t,
              });
              setProps({
                scheduleViewModel,
                changeTimezoneCallback,
              });
            })
            .catch((e) => {
              console.log('Getting schedules failed ', e);
              scheduleViewModel = {
                status: SchedulingSectionStatus.FAILED,
                isBookable: false,
              };
              setProps({
                scheduleViewModel,
              });
            });
        }
      } else {
        const isEditorX =
          flowAPI.controllerConfig.config.style.styleParams.booleans.responsive;
        viewModel = dummyViewModelFactory({ t, isEditorX });
        const dummyBusinessInfo = {
          timeZone: 'UTC',
          regionalSettingsLocale: flowAPI.environment.language,
          dateRegionalSettingsLocale: flowAPI.environment.language,
        };
        scheduleViewModel = dummySchedulingViewModel({
          t,
          businessInfo: dummyBusinessInfo,
          scheduleDays: settings.scheduleDays,
        });

        flowAPI.biLogger &&
          flowAPI.biLogger.util.updateDefaults({
            ...biDefaults,
            ...generateWidgetDefaults(appParams, platformAPIs, isEditor),
          });
      }

      const { userMessage, showUserMessage } = initUserMessage(setProps);

      setProps({
        navigateToCalendar,
        scheduleViewModel,
        viewModel,
        userMessage,
      });
    },
    updateConfig($w, config) {
      const updatedPublicData = config.publicData.COMPONENT || {};
      const updatedSettings = getSettingsValues(
        updatedPublicData,
        settingsParams,
      );
      if (settings.scheduleDays !== updatedSettings.scheduleDays) {
        const dummyBusinessInfo = {
          timeZone: 'UTC',
          regionalSettingsLocale: flowAPI.getSiteLanguage('en'),
        };

        const scheduleViewModel = dummySchedulingViewModel({
          t,
          businessInfo: dummyBusinessInfo,
          scheduleDays: updatedSettings.scheduleDays,
        });

        setProps({
          scheduleViewModel,
        });
      }
    },
  };
};

export default createController;
