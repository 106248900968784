import {
  ServiceLocation,
  ServiceLocationType,
} from '@wix/bookings-uou-types/dist/src';

export class LocationMapper {
  constructor(private clientLocationText: string) {}
  public text({
    serviceLocation,
    useAddressAsFallback = false,
  }: {
    serviceLocation: ServiceLocation | undefined;
    useAddressAsFallback?: boolean;
  }): string {
    switch (serviceLocation?.type) {
      case ServiceLocationType.OWNER_CUSTOM:
        return serviceLocation.address as string;
      case ServiceLocationType.CLIENT_PLACE:
        return this.clientLocationText;
      case ServiceLocationType.OWNER_BUSINESS:
        return this.businessLocationText(serviceLocation, useAddressAsFallback);
      default:
        return '';
    }
  }

  private businessLocationText(
    serviceLocation: ServiceLocation,
    useAddressAsFallback: boolean,
  ) {
    const businessLocationName = serviceLocation?.businessLocation?.name;

    if (businessLocationName) {
      return businessLocationName;
    }

    return useAddressAsFallback
      ? serviceLocation.businessLocation!.address!.formattedAddress!
      : '';
  }
}
