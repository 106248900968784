import { ServiceType } from '@wix/bookings-uou-types/dist/src';

export interface DurationMapperOptions {
  hourUnit: string;
  minuteUnit: string;
  hourAriaUnit?: string;
  minuteAriaUnit?: string;
  durationVaries?: string;
  coursePassedText?: string;
  courseInFutureText?: string;
}

export class DurationMapper {
  constructor(
    private regionalSettingsLocale: string,
    private options: DurationMapperOptions,
    private formatter,
  ) {}

  private isDateInCurrentYear = (date: Date) => {
    const now = new Date();
    return now.getFullYear() === date.getFullYear();
  };

  private isDateInThePast = (date: Date) => {
    const now = new Date();
    return date.getTime() < now.getTime();
  };

  private courseText({ startDateAsUTC }: { startDateAsUTC?: string }) {
    if (startDateAsUTC) {
      const startDate = new Date(startDateAsUTC);

      const dateFormat = this.isDateInCurrentYear(startDate)
        ? { month: 'short', day: 'numeric' }
        : { year: 'numeric', month: 'short', day: 'numeric' };

      const formattedDate = new Intl.DateTimeFormat(
        this.regionalSettingsLocale,
        dateFormat,
      ).format(startDate);

      return this.isDateInThePast(startDate)
        ? this.formatter(this.options.coursePassedText, {
            date: formattedDate,
          })
        : this.formatter(this.options.courseInFutureText, {
            date: formattedDate,
          });
    }
    return '';
  }

  private formatDurationFromMinutes({
    durationInMinutes,
    hourUnit,
    minuteUnit,
  }) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    const hourText = this.formattedHours(hours, hourUnit);
    const minuteText = this.formatter(minuteUnit, { minutes });
    return hours ? hourText + (minutes ? ` ${minuteText}` : '') : minuteText;
  }

  private formattedHours(hours: number, hourUnit: string) {
    const hourText = this.formatter(hourUnit, { hours });
    const hoursAriaLabelFormatterKey = 'duration.units.aria-hours';
    const isHourTextForAriaLabel = hourUnit === hoursAriaLabelFormatterKey;
    return hours === 1 && isHourTextForAriaLabel
      ? hourText.slice(0, -1)
      : hourText;
  }

  public durationTextFromMinutes(durationInMinutes) {
    const hourUnit = this.options.hourUnit;
    const minuteUnit = this.options.minuteUnit;
    return this.formatDurationFromMinutes({
      durationInMinutes,
      hourUnit,
      minuteUnit,
    });
  }

  public durationAriaLabelFromMinutes(durationInMinutes) {
    const hourUnit = this.options.hourAriaUnit;
    const minuteUnit = this.options.minuteAriaUnit;
    return this.formatDurationFromMinutes({
      durationInMinutes,
      hourUnit,
      minuteUnit,
    });
  }

  public durationText({
    serviceType,
    isDurationVaries,
    durationInMinutes,
    startDateAsUTC,
  }: {
    serviceType: ServiceType;
    isDurationVaries?: boolean;
    durationInMinutes?: number;
    startDateAsUTC?: string;
  }) {
    if (serviceType === ServiceType.COURSE) {
      return this.courseText({ startDateAsUTC });
    }

    if (isDurationVaries) {
      return this.formatter(this.options.durationVaries);
    }

    if (durationInMinutes) {
      return this.durationTextFromMinutes(durationInMinutes);
    }

    return '';
  }
}
